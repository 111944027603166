import { Image } from "react-bootstrap";
import Logo from "../../assets/images/healthcare-referral-logo-small.png";
import { FaFacebookF, FaInstagram, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";
import { footerNav } from "../../helper/navLink";
import { Helmet } from "react-helmet";
import { useState } from "react";
import { Api } from "api/api";
import SweetAlert from 'react-bootstrap-sweetalert';
import ShieldIcon from '@mui/icons-material/Shield';
import { useSelector } from "react-redux";

const Footer = () => {
  const [loader, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [errorMsg, setErrorMessage] = useState('');
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const { user, isLoggedIn } = useSelector((state) => state.user);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const sumbitNewsLetter = () => {
    if (email !== '') {
      setLoading(true);
      let data = new FormData();
      data.append("email", email);

      Api.sendNewsLetter(data)
        .then((res) => {
          setLoading(false);
          setShowDialog(true);
          setEmail('');
        })
        .catch((res) => {
          setLoading(false);
          setShowErrorDialog(true);
          setErrorMessage(res.response?.data?.message);
        })
    }
    else {
      setEmailError('Email is required');
    }
  }

  const handleClose = () => {
    setShowDialog(false);
  };

  const handleCloseError = () => {
    setShowErrorDialog(false);
  };
  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css"
        />
      </Helmet>
      <footer className="footer flex justify-center">
        <div className="justify-center place-items-start grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 max-w-screen-xl gap-3 px-3">
          <div className="mr-5 p-3">
            <div className="w-auto h-auto object-cover">
              <Image src={Logo} alt="" className="w-3/4 md:w-auto" />
            </div>
          </div>
          <div className="p-3">
            <h4 className="text-xl font-semibold pb-3">About Us</h4>
            <p className="text-lg">
              We are a team of dedicated individuals at Healthcare Referral Pro,
              driven by the passion to provide professional and secure medical
              referrals.
            </p>
          </div>
          <div className="p-3 w-full">
            <h4 className="text-xl font-semibold pb-3">Newsletter</h4>
            <form>
              <div className="form-group py-2">
                <input
                  type="email"
                  className="form-control w-full "
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {
                  emailError !== '' && (
                    <label className="text-danger">{emailError}</label>
                  )
                }

              </div>
              {
                loader ? (
                  <>
                    <div className="flex items-center">
                      <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-gray-900"></div>
                    </div>
                  </>
                )
                  :
                  (
                    <>
                      <button type="button" onClick={(e) => sumbitNewsLetter()} className="cbtn mt-2 rounded-none">
                        Submit
                      </button>
                    </>
                  )
              }

            </form>
          </div>
          <div className="p-3 contactus">
            <h4 className="text-xl font-semibold pb-3">Contact Us</h4>
            <ul className="pl-0">
              <li>
                <p>
                  <strong>Phone:</strong> (479) 527-0337
                </p>
              </li>
              <li>
                <a href="https://www.facebook.com/healthcarereferralpro/" target="_blank">
                  <FaFacebookF />
                </a>
                <a href="https://www.linkedin.com/in/healthcare-referral-pro/" target="_blank" >
                  <i className="fab fa-instagram" aria-hidden="true"></i>
                  <FaLinkedin />
                </a>
                <a href="https://www.instagram.com/healthcarereferralpro/" target="_blank">
                  <FaInstagram />
                </a>
                <a href="https://lordsbook.com/pages/healthcarereferralpro?ref=qs " target="_blank" >
                  <ShieldIcon style={{ marginTop: '-20px', fontSize: '30px' }} />
                  {/* <img src="https://hfmgpreview.com/healthcare/wp-content/uploads/2024/04/lordsbook-logo.png" alt="placeholder" /> */}
                  {/* <image src="https://hfmgpreview.com/healthcare/wp-content/uploads/2024/04/lordsbook-logo.png" style="margin-top:0px;" /> */}
                </a>
              </li>
            </ul>
          </div>
        </div >
      </footer >
      <section className="footer-bottom ">
        <div className="container max-w-screen-xl flex flex-wrap justify-between">
          <div className="fmenu">
            <ul>
              {footerNav?.map((item, i) => {
                return (
                  <li key={i}>
                    <Link to={item.path}>{item.title}</Link>
                  </li>
                );
              })}
              {
                isLoggedIn ? (
                  <li>
                    <Link to={'/logout'}>Logout</Link>
                  </li>
                )
                  :
                  (
                    <>
                      <li>
                        <Link to={'/login'}>Login</Link>
                      </li>
                      <li>
                        <Link to={'/register'}>Register</Link>
                      </li>
                    </>
                  )
              }

            </ul>
          </div>
          <div className="rpart">
            <p>
              © Copyright {currentYear} | <a href="">Him First Media Group</a>
            </p>
          </div>
        </div>
      </section>
      <SweetAlert
        success
        show={showDialog}
        title="Thank you for subscribe."
        onConfirm={handleClose}
        confirmBtnCssClass="bg-primary-500 rounded-lg text-xl text-white hover:bg-blue-600"
      />
      <SweetAlert
        error
        show={showErrorDialog}
        title={errorMsg}
        onConfirm={handleCloseError}
        confirmBtnCssClass="bg-primary-500 rounded-lg text-xl text-white hover:bg-blue-600"
      />
    </>
  );
};

export default Footer;
